// src/api/ApiService.js

class ApiService {
    constructor() {
        this.baseURL = 'https://api.siriuslab.kz/api';
        this.timeout = 10000; // Request timeout
    }

    async getUserContext(contextKey, username, password) {
        const endpoint = `${this.baseURL}/app-ms-adapter/context/${contextKey}/employee`;
        try {
            console.log('SEND REQUEST');

            const controller = new AbortController();
            const timeoutId = setTimeout(() => controller.abort(), this.timeout);

            const response = await fetch(endpoint, {
                method: 'GET',
                headers: {
                    'Authorization': 'Basic ' + btoa(`${username}:${password}`)
                },
                signal: controller.signal
            });

            clearTimeout(timeoutId);

            if (!response.ok) {
                throw new Error(`Error fetching user context: ${response.statusText}`);
            }

            return await response.json();
        } catch (error) {
            console.error('Error fetching user context:', error);
            throw error;
        }
    }

    async updateUserSettings(accountId, data, username, password) {
        const endpoint = `${this.baseURL}/account/${accountId}/settings/`;
        console.log('Sending data');

        try {
            const controller = new AbortController();
            const timeoutId = setTimeout(() => controller.abort(), this.timeout);

            const response = await fetch(endpoint, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + btoa(`${username}:${password}`)
                },
                body: JSON.stringify(data),
                signal: controller.signal
            });

            clearTimeout(timeoutId);

            if (!response.ok) {
                throw new Error(`Error updating user settings: ${response.statusText}`);
            }

            return await response.json();
        } catch (error) {
            console.error('Error updating user settings:', error);
            throw error;
        }
    }
}

export default new ApiService();
